import React from 'react'
import styled from 'styled-components'
import {
  Modal,
  PopupBody,
  PopupContent
} from '../../../youtalk-storybook/src/ui'
import { Text } from '../../atoms/Text'
import { selectDeviceSizeIsMobile } from '../../state/reducers/deviceSlice'
import { size } from '../../constants'
import { useSelector } from 'react-redux'
import { useTrackVideoOpen } from '../amplitude/trackVideoOpen'

export const StyledModal = styled(Modal)`
  &&& {
    & ${PopupBody} {
      max-width: 1008px;
    }

    & ${PopupContent} {
      max-width: 1008px;
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const VideoContainer = styled.div`
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const Title = styled(Text.Medium)`
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 24px;
`

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
  height: 540px;

  @media (max-width: ${size.sm}) {
    height: 100%;
    max-height: 400px;
  }

  @media (max-width: ${size.xs}) {
    max-height: 212px;
  }
`

export const VideoModal = styled(
  ({ className, videoURL, source, title, hide }) => {
    const isMobile = useSelector(selectDeviceSizeIsMobile)
    useTrackVideoOpen({
      source: source
    })
    return (
      <StyledModal
        className={className}
        hide={hide}
        showCloseButton={!isMobile}
        title={isMobile ? '' : title}
      >
        <Container>
          {isMobile && <Title>{title}</Title>}
          <VideoContainer>
            <Frame
              allowfullscreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              frameBorder="0"
              referrerPolicy="strict-origin-when-cross-origin"
              src={`${videoURL}?rel=0`}
              title="YouTube video player"
            ></Frame>
          </VideoContainer>
        </Container>
      </StyledModal>
    )
  }
)``
